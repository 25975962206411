/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

//@import './font/stylesheet.css';
@import './variables/variables';


@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Roboto:wght@100;400&display=swap');

p {
	font-family: 'Lato', sans-serif !important;
	font-family: 'Roboto', sans-serif !important;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Roboto:wght@100&display=swap');

h1 {
	font-family: 'Lato', sans-serif !important;
	font-family: 'Roboto', sans-serif !important;
}

.navigation-main>li.nav-item>ul.menu-content>div.collapse>li.nav-item {
	padding-left: 20px;
	transition: all 1s;
}

h2 {
	font-family: 'Lato', sans-serif !important;
	font-family: 'Roboto', sans-serif !important;
}


h3 {
	font-family: 'Lato', sans-serif !important;
	font-family: 'Roboto', sans-serif !important;
}


h4 {
	font-family: 'Lato', sans-serif !important;
	font-family: 'Roboto', sans-serif !important;
}

h5 {
	font-family: 'Lato', sans-serif !important;
	font-family: 'Roboto', sans-serif !important;
}

.heading {
	font-family: 'Lato', sans-serif !important;
	font-family: 'Roboto', sans-serif !important;
}

*label {
	font-family: 'Lato', sans-serif !important;
	font-family: 'Roboto', sans-serif !important;
}

*ul li {
	font-family: 'Lato', sans-serif !important;
	font-family: 'Roboto', sans-serif !important;
}

*table tr td {
	font-family: 'Lato', sans-serif !important;
	font-family: 'Roboto', sans-serif !important;
}

*span {
	font-family: 'Lato', sans-serif !important;
	font-family: 'Roboto', sans-serif !important;
}

// USAGE
body {
	@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,400;1,400;1,500&display=swap');
	//font-family: 'Visby CF', Helvetica, Arial, serif !important;
	font-family: 'Plus Jakarta Sans', sans-serif;
}

.blur-effect {
	filter: blur(4px);
}

.modal {
	background: #0000008a;
}

.bg-primary-color {
	background-color: #0b3365 !important;
}

.dashboard-ratio {
	color: rgb(35, 149, 105);
	background-color: rgb(206, 234, 225);
	margin-left: 4px;
	// display: flex;
	// justify-content: center;
	// align-items: center;
	padding: 0px 0.5rem 0px 0.5rem;
}

.shortcut-buttons-flatpickr-button {
	// margin: 4px;
	border: 0;
	padding: 3px;
	padding-inline: 9px;
	font-size: 13px;
	font-weight: 500;
	background: $primary;
	color: white;
	border-radius: 3px;
	// padding-block: 5px;
}

.shortcut-buttons-flatpickr-button:hover {
	box-shadow: 0px 3px 5px $primary;
	background: $primary;
	// font-size: 14px;
}

.shortcut-buttons-flatpickr-buttons {
	display: flex;
	justify-content: space-around;
	margin-top: 8px;
	// margin-right: 5px;
	margin-bottom: 5px;
}

.w-20 {
	width: 20%;
}

.light-border {
	border: 1px solid lightgray,
}

.filter-blur {
	filter: blur(1px);
}


.custom-row {

	display: flex;
	border-top: 1px solid rgba(47, 43, 61, 0.16);
	-webkit-box-align: center;
	align-items: center;
	position: relative;
	text-decoration: none;
	width: 100%;
	box-sizing: border-box;
	text-align: left;
	padding: 0.5625rem 16px;
	cursor: pointer;
	background: rgba(164, 161, 172, 0.16);
	-webkit-box-pack: justify;
	justify-content: space-between;
	transition: border 0.15s ease-in-out 0s, transform 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	background-color: rgba(47, 43, 61, 0.04);

}

.custom-row:hover {
	//background: rgba(164, 161, 172, 0.16);
	transform: translateY(-2px);
	box-shadow: 0 3px 10px 0 rgba(164, 161, 172, 0.16);
	transition: all 0.2s;
	z-index: 1;
}

.custom-mail {
	margin-right: 1rem;
	display: flex;
	overflow: hidden;
	-webkit-box-align: center;
	align-items: center;
}

.custom-brand-icon {

	background: $primary;
	margin-bottom: 0px;
	text-align: center;
	color: white;
	padding: 7px;
	border-radius: 8px;
	font-weight: 600;
}


.tool-icon:hover {
	color: white;
	background-color: rgba(9, 81, 241, .7) !important
}


.react-dataTable .rdt_TableBody .rdt_TableRow {
	color: $black;
}

.navigation {
	font-size: 14px;
}

.pr-1 {
	padding-right: 1rem;
}

.vc-rating {
	font-size: 16px;
}

.w-100 {
	width: 100%;
}

.pointer {
	cursor: pointer;
}

.text-align-center {
	text-align: center;
}

.product-sidebar {
	margin-top: 40px;
	margin-bottom: 40px;
	width: 40px;
	height: 40px;
	background-color: #DAE2F0;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	transition: background-color 0.3s;

}

.product-sidebar:hover {
	background-color: #8B56E8;

}

.justify-center {
	justify-content: center;
}

.align-center {
	align-items: center;
}

.flex-column {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

.justify-evenly {
	justify-content: space-evenly;
}

.text-end {
	text-align: end;
}

.italic {
	font-style: italic;
}

.primary-color {
	color: $primary;
}

.p-10 {
	padding: 10px;
}

.ml-40 {
	margin-left: 40px;
}

.ml-12 {
	margin-left: 12px;
}

.text-white {
	color: white;
}


.ml-6 {
	margin-left: 6px;
}

.ml-3 {
	margin-left: 3px;
}

.mr-40 {
	margin-right: 40px;
}

.pr-40 {
	padding-right: 40px;
}

.pl-40 {
	padding-left: 40px;
}

.pb-10 {
	padding-bottom: 10px;
}

.p-5px {
	padding: 5px;
}

.p-10px {
	padding: 10px
}

.p-20px {
	padding: 20px;
}

.bg-white {
	background-color: white;
}

.bg-primary {
	background-color: $primary;
}

.mb-20px {
	margin-bottom: 20px;
}

.v-chip {
	color: #50cd89;
	font-weight: 600;
	padding: 3px 10px;
	border-radius: 5px;
	background-color: #e8fff3;
	display: flex;
	align-items: center;
}

.cross {
	content: "\u2718";
	/* Unicode for cross mark */
	position: absolute;
	top: 2px;
	left: 2px;
}

.custom-sidebar {
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 57px;
	height: 100vh;
	background: rgb(255, 255, 255);
	border-right: 1px solid rgb(238, 240, 242);
	position: fixed;
	// z-index: 1200;
	padding: 12px;
	transition: width 0.2s ease-in-out 0s;
	transform-origin: left center;
}

.chip {
	color: rgb(83 134 247 / 95%);
	font-weight: 600;
	padding: 3px 10px;
	border-radius: 5px;
	background-color: rgb(10 82 242 / 27%);
	display: flex;
	align-items: center;
}

.Web-Call {
	color: rgb(244 149 58);
	font-weight: 600;
	padding: 3px 10px;
	border-radius: 5px;
	background-color: rgb(250 181 114 / 40%);
	;
	display: flex;
	align-items: center;
}


.Best-Price {
	color: rgb(0, 207, 232);
	font-weight: 600;
	padding: 3px 10px;
	border-radius: 5px;
	background-color: rgb(167 233 241 / 53%);
	display: flex;
	align-items: center;
}

.rounded-5 {
	border-radius: 5px
}

.rounded-15 {
	border-radius: 15px;
}

.rounded-40 {
	border-radius: 40px;
}

.btn-primary-large {
	padding-left: 4rem;
	padding-right: 4rem;
}

/* Calender */
.fc-day-today {
	background-color: rgba(51, 48, 60, 0.04) !important
}

.fc-event-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.rdt_TableCell span.d-block.fw-bold.text-truncate {
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	overflow: hidden !important;
	width: 89% !important;
}

.spinner-wrap {
	width: 100%;
	text-align: center;
}

.copybtn-wrap {
	width: 25px;
	height: 25px;
	text-align: center;
}



/* Styling for print Hangtag */
@media print {

	html,
	body {
		height: auto;
		// page-break-inside: avoid;
		// page-break-before: avoid;
		// page-break-after: avoid;
		overflow: hidden;
		// height: 4in;
		// width: 2.5in;
	}
}


@page {
	margin: 0;
}

.color-black {
	color: #424242;
}

.color-white {
	color: white;
}

.fs-Xlarge {
	font-size: -webkit-xxx-large
}

.word-break {
	word-break: break-all;
}

.fs-heading {
	font-size: 23px;
}

.ff-fantasy {
	font-family: fantasy;
}

.fs-8px {
	font-size: 8px;
}

.fs-12 {
	font-size: 12px;
}

.spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}



.custom-option-selected {
	margin-left: auto;
	background-color: $primary;
	color: white;

	border-radius: 4px;
	font-size: 12px;
	padding: 3px;
}


.grid-fontSize {
	font-size: 13px;
}

.fs-7px {
	font-size: 7px;
}

.fw-800 {
	font-weight: 800;
}

.fw-900 {
	font-weight: 900;
}

.color-lightgrey {
	color: lightgrey;
}

.react-dataTable.dealer-table [data-testid*="expander-button-"][disabled] {
	display: none !important;
}

.c-color-box {
	padding: 0;
}

.c-h-col-1 {
	flex: 6;
}

.c-h-col-2 {
	flex: 2;
}

.c-h-col-3 {
	flex: 2;
	padding-left: 15px;
}

.c-sd-app-logo {
	width: 200px;
}

.customer-b-wrap .b-h-s-wrap {
	align-items: normal;
	justify-content: normal !important;
}

.sd-toast-wrap div[aria-live="polite"] {
	justify-content: normal;
}

.sd-toast-wrap div[aria-live="polite"] .d-flex {
	width: 240px;
	justify-content: normal;
}

.valuation-form-bottom {
	width: 28%;
	float: right;
}

.valuation-form-bottom .spinner-border.text-primary {
	float: right;
}

.blink-me {
	animation: blinker 1s linear infinite;
}

@keyframes blinker {
	50% {
		border: 1px solid #0951f1;
	}
}

.offered-amount-f[readonly] {
	background-color: #fff;
	color: #424242;
	opacity: 1;
}

.flex-full-width {
	flex-wrap: nowrap !important;
	flex-direction: row !important;
	width: 100%;

}

.flex-full-width>.flexcol-1 {
	flex: 1
}

.flex-full-width>.flexcol-2 {
	flex: 2
}

.flex-full-width>.flexcol-3 {
	flex: 3
}

.flex-full-width>.flexcol-4 {
	flex: 4
}

.flex-full-width>.flexcol-5 {
	flex: 5
}

.flex-full-width>.flexcol-6 {
	flex: 6
}

.flex-full-width>.flexcol-7 {
	flex: 7
}

.flex-full-width>.flexcol-8 {
	flex: 8
}

.flex-full-width>.flexcol-9 {
	flex: 9
}

.flex-full-width>.flexcol-10 {
	flex: 10
}

.sc-hLBbgP:has(.flex-full-width) {
	flex-direction: column;
}

.sc-hLBbgP {
	overflow: hidden;
}

.e-disabled {
	cursor: not-allowed;
}

.e-disabled * {
	pointer-events: none;
}

.offer-amount-wrap {
	position: relative;
}

svg.offer-amount-edit {
	position: absolute;
	top: 35px;
	right: 25px;
}

.react-hot-toast {
	margin: auto;
}

input[name="vin_number"],
input[name="plateNo"] {
	text-transform: uppercase;
}

input[name="vin_number"]::placeholder,
input[name="plateNo"]::placeholder {
	text-transform: capitalize;
}

.offer-amount-wrap .offer-amount-edit {
	cursor: pointer;
}

.v-v-loading-wrap {
	text-align: center;
}

.toggle-v-l.form-check.form-switch {
	margin-top: -7px;
}

.upload-logo-wrap p {
	margin: 0 !important;
}

.c-ifram-div {
	display: none;
}

.v-i-b-h-wrao {
	text-align: center;
}

.custom-Avatar {
	width: 35px;
	height: 35px;
	//     background-color: #4077f3;
	background-color: $primary;
	/* text-align: center; */
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	font-size: 20px;
	color: white;
	cursor: pointer;
}


.cursor-pointer {
	cursor: pointer;
}

.fw .allowance-col {
	width: 430px;
	margin-left: 5px;
}

.hw .allowance-col {
	width: 263px;
	margin-left: 5px;
}

.fw .allowance-input {
	width: 100%;
	height: 80px;
}

.hw .allowance-input {
	width: 263px;
	height: 80px;
}

html {
	scroll-behavior: initial !important;
}

body {
	scroll-behavior: smooth;
}

button.swal2-cancel.swal2-styled {
	margin-left: 5px;
}

.email-class {
	border-radius: 20px;
}

.welcome {
	font-weight: 900;
	font-size: xx-large;
}

.swal-container {
	z-index: 99999;
    box-shadow: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.swal-confirm {
	background-color: #0b3365;
	box-shadow: none !important;
}

.swal-cancel:hover {
	box-shadow: none !important;
}

.swal-confirm:hover {
	box-shadow: none !important;
}

.icon-color {
	color: #0b3365;
}

.cancel-btn {
	position: absolute;
	margin-left: -15px;
	margin-top: -10px;
	color: #fff;
	background: #0b3365;
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
	width: 25px;
	height: 25px;
}

.outlinewhite {
	outline: 1px solid rgba(0, 0, 0, 0.5);
	padding: 40px;
	border-radius: 10px;
	offset: 2px;
	box-shadow: inset;
	background-color: #fff;
}


.outlineblack {
	outline: 1px solid rgba(253, 252, 252, 0.5);
	padding: 40px;
	border-radius: 10px;
	offset: 2px;
	box-shadow: inset;
	background-color: #28304648
}

.flex-direction-column {
	flex-direction: column;
}

.themeside {
	padding-right: 80px;
	margin: 0;
}

.displayingfooter {
	display: flex;
	align-items: end;
	justify-content: end;
	margin: 0px;
	bottom: 0px;

}

.sidebar-slider {
	width: 50% !important
}


@media (min-width: 200px) and (max-width: 900px) {
	.sidebar-slider {
		width: 100% !important;
	}

	.custom-header {
		width: 100%;
	}
}


.max-width-90 {
	max-width: 90%;
}

// HUb LAYOUT CSS CHANGES 

.submit--button--container {
	display: flex;
	justify-content: end;
	width: 100%;
}

.Card--Header--Title {
	font-weight: 900 !important;
	font-size: 20px;
	color: $dark;
}

.Card--Body label {
	font-size: 12px;
	font-weight: 900;
	color: $dark;
}

.Amount {
	font-size: 18px;
	font-weight: 900;
	color: $dark;
}

.dollar--sign {
	font-size: 14px;
	margin-right: 2px;
}

.mutted-text {
	font-size: 11px;
	color: gray;
}

.percentage {
	font-size: 12px;
	padding: 4px;
	color: rgba(1, 131, 1, 0.863);
	background-color: rgba(27, 221, 27, 0.233);
	margin-left: 4px;
	border-radius: 4px;
}

.percentageDown {
	font-size: 12px;
	padding: 4px;
	color: rgba(131, 5, 1, 0.863);
	background-color: rgba(221, 33, 27, 0.233);
	margin-left: 4px;
	border-radius: 4px;
}

.icon {
	font-size: 20px;
}

.rotate-btn {
	bottom: 50%;
	// left :98%;
	transform: rotate(90deg);
	right: -65px;
}

.link-btn {
	background-color: #254061 !important;
	border-color: #385d8a !important;
	color: #fff !important;
	font-size: 15px;
}

.max-event-height {
	height: 200px;
}

.paginationHover :hover {
	// font-size: medium;
	color: blue;
	cursor: pointer;
}

.object-fit-contain {
	object-fit: contain;
}

*::-webkit-scrollbar {
	width: 0.4em;
	height: 6px;

}

*::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	border-radius: 20px;
}

.timeline .timeline-item:not(:last-child) {
	padding-bottom: 0px;
}

.timeline .timeline-item .timeline-event {
	min-height: 3rem
}

.btn-outline-secondary {
	background-color: #55565a !important;
	color: #ffffff;
}

.card-body {
	padding: 0px 1.5rem;
}

.main-logo {
	width: 200px;
}

.c-row {
	margin-top: 15px;
}

//Help Center - Left Right Arrow
.swiper-button-next,
.swiper-button-prev {
	width: 40px !important;
	height: 40px !important;
	border-radius: 55%;
	background-color: #fff;
	transform: translateY(-50%);

}

.swiper-button-next {
	right: 10px;
}

.swiper-button-prev {
	left: 10px;
}

.swiper-button-prev.swiper-button-disabled {
	opacity: 0 !important;
}

.swiper-button-next.swiper-button-disabled {
	opacity: 0 !important;
}

.ant-select-selection-item {
	background-color: #0b3365 !important;
	color: #fff !important;
}

.ant-select-selection-item-remove {
	color: #fff !important;
}

@media print {
	.page {
		page-break-after: always;
	}
}

/* Remove the blue background from the selected item in the input */
.ant-select-selector {
	background-color: transparent !important;
	color: #000 !important;
}

/* Additional styling for the input box */
.ant-select-selection-item {
	background-color: transparent !important;
	color: #000 !important;
}

// Twilio Modal

.custom-search-button .ant-btn-primary {
    background-color: #0b3365; /* Replace with your primary color */
    border-color: #0b3365;     /* Border color to match */
	border-top-right-radius: 12%;
	border-bottom-right-radius: 12%;
}

.custom-search-button .ant-btn-primary:hover {
    background-color: #0b3365; /* Optional: A darker shade for hover effect */
    border-color: #0b3365;
}

.custom-select .ant-select-selector {
    padding: 6px 0px; /* Adjust as needed */
    height: auto; /* To prevent fixed height */
}